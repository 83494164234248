import { useEffect, useState } from 'react';
import UK from '../assets/uk.png';
import CAN from '../assets/canada.png';
import CANUS from '../assets/canus.jpg';
import US from '../assets/us.png';
import VICTORIA from '../assets/victoria.jpg';
const regions = [
  [
    'Victoria',
    'Victoria',
    VICTORIA,
    'https://aslamhusainphotography.pixieset.com/canadastore',
    'Photos printed by Island Blue Print',
    false,
  ],
  [
    'NA',
    'North America',
    CANUS,
    'https://aslamhusainphotography.pixieset.com/',
    'Photos printed by ProDPI',
    true,
  ],

  [
    'GB',
    'UK',
    UK,
    'https://aslamhusainphotography.pixieset.com/ukstore/',
    'Photos printed by Luxley Labs',
    true,
  ],
];

const CountryName = ({ ipData, setHoverMessage, setShippingAvailable, setImagesLoaded }) => {
  const [currentRegion, setCurrentRegion] = useState(null);

  const goToStore = (url) => {
    window.location.href = url;
  };

  const handleRegionHover = (event, message, pickupAvailable) => {
    setHoverMessage(message);
    setShippingAvailable(pickupAvailable);
  };

  const getCurrentRegion = () => {
    let region;
    if (ipData) {
      // region is set to whatever teh client's country is,
      // unless the city is Victoria
      region = ipData.city === 'Victoria' ? 'Victoria' : ipData.country_code;
    }
    console.log('ipData', ipData);
    return region;
  };

  useEffect(() => {
    setCurrentRegion(getCurrentRegion());
    const images = [CAN, US, UK];
    const loadImage = (image) => {
      return new Promise((resolve, reject) => {
        const imgElement = new Image();
        imgElement.onload = function () {
          resolve();
        };
        imgElement.src = image;
        imgElement.onerror = (error) => reject(new Error(error));
      });
    };

    Promise.all(images.map((image) => loadImage(image))).then(() => {
      setImagesLoaded(true);
    });
  }, [ipData]);

  return (
    <div className="store-regions" onMouseOut={() => setHoverMessage('')}>
      {regions.map((region) => {
        const [regionCode, regionName, img, url, message, shippingAvailable] = region;
        console.log(currentRegion, regionCode);
        return (
          <div
            className="region"
            key={regionName}
            onMouseOver={(event) => handleRegionHover(event, message, shippingAvailable)}
          >
            <div className="region-name">
              <p>{regionName}</p>
            </div>
            <button
              className={currentRegion === regionCode ? 'active' : ''}
              onClick={() => goToStore(url)}
            >
              <img alt={regionName} src={img} />
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default CountryName;
