export const getBasename = () => {
  const { hostname, port } = window.location;
  let basename = '/';
  if (hostname === 'localhost') {
    switch (port) {
      case '8888':
        basename = '/local_folder/stores.aslamhusainphotography.com/build/';
        break;
      case '3000':
        basename = '/';
        break;
      default:
        basename = '/';
    }
    return basename;
  }
  console.log(hostname, port);
};
