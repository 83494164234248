import { Truck } from 'react-bootstrap-icons';

const CountryName = ({ ipData }) => {
  const getCountryName = () => {
    let countryName = ipData.country_name;
    if (ipData.country_name === 'United States' || ipData.country_name === 'United Kingdom') {
      countryName = `the ${ipData.country_name}`;
    }
    return countryName;
  };

  if (!ipData || (ipData?.continent_code !== 'EU' && ipData?.continent_code !== 'NA')) {
    return (
      <>
        <h1>Choose your store</h1>
        <h2>
          Save on shipping <Truck></Truck>
        </h2>
        <p>Get prints prepared and delivered by your nearest lab</p>
      </>
    );
  }

  return (
    <>
      {ipData.continent_code === 'NA' && ipData.city !== 'Victoria' ? (
        <>
          <h1>Shopping from {getCountryName()}?</h1>
          <div className="spacer"></div>
          <h2>
            Save on shipping <Truck></Truck>
          </h2>
          <p>
            Browse the <strong>North American</strong> store and get prints and frames delivered to
            your door.
          </p>
        </>
      ) : null}
      {ipData.continent_code === 'EU' || ipData.country_code === 'GB' ? (
        <>
          <h1>Shopping from {getCountryName()}?</h1>
          <div className="spacer"></div>
          <h2>
            Save on shipping <Truck></Truck>
          </h2>
          <p>
            Browse the <strong>UK store</strong> to get prints delivered from your nearest photo
            lab.
          </p>
        </>
      ) : null}
      {ipData.city === 'Victoria' ? (
        <>
          <h1>Shopping from Victoria, B.C.?</h1>
          <div className="spacer"></div>
          <p>
            Browse the <strong>Victoria</strong> store and buy locally made prints.
          </p>
          <p>
            Pick them up directly from Island Blue Print or stop by to pick them up from me (Oak
            Bay) when they're ready.
          </p>
        </>
      ) : null}
    </>
  );
};

export default CountryName;
