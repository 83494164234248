import { useState, useEffect } from 'react';
import ChooseStore from './components/ChooseStore';
import { BrowserRouter as Router, Routes, Route, useParams, useLocation } from 'react-router-dom';
import { getBasename } from './components/utils';
import logo from './assets/logo-2021-handwriting-dark.png';
import './App.css';
import { ArrowLeft } from 'react-bootstrap-icons';

function App() {
  console.log('public url', process.env.PUBLIC_URL);
  console.log('router basename', process.env.REACT_APP_ROUTER_BASENAME);
  console.log('node_env', process.env.NODE_ENV);

  const basename = getBasename();
  /**
   * Router basename env variable set in build.
   */
  return (
    <Router basename={basename}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/error/:code" element={<ErrorDocument />}></Route>
        <Route path="*" element={<ErrorDocument />} />
      </Routes>
    </Router>
  );
}

const Home = () => {
  const [ipData, setIpData] = useState('');
  const [iframeLoaded, setIframeLoaded] = useState(true);
  const [errorObj, setErrorObj] = useState(null);
  const getUserLocation = () => {
    return fetch('https://ipapi.co/json/')
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw new Error(error);
      })
      .then((data) => data);
  };

  const getIpData = async () => {
    let data;
    try {
      data = await getUserLocation();
    } catch (error) {
      setErrorObj(error);
      return;
    }
    if (data) {
      setIpData(data);
    }
  };

  useEffect(() => {
    getIpData();
  }, []);

  return (
    <div className="App">
      <ChooseStore ipData={ipData} iframeLoaded={iframeLoaded} errorObj={errorObj} />
      <img className="background-logo" src={logo}></img>
      {/* <iframe
        title="store"
        src="https://aslamhusainphotography.pixieset.com"
        style={{ height: '100vh', width: '100vw', pointerEvents: 'none' }}
        onLoad={() => setIframeLoaded(true)}
      ></iframe> */}
    </div>
  );
};

const ErrorDocument = () => {
  const params = useParams();
  const [errorType, setErrorType] = useState(params?.code || 'none');

  const goBackToMainPage = (event) => {
    window.location = '/';
  };

  useEffect(() => {
    if (isNaN(errorType)) {
      setErrorType('404 error');
    } else {
      setErrorType(`${errorType} error`);
    }
  }, []);

  return (
    <div className="error-page">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      <h1>{errorType}</h1>
      <h2>Well, this is awkward! We couldn't find that page.</h2>
      <p>
        <button onClick={goBackToMainPage} href="/">
          <ArrowLeft></ArrowLeft> Head back to the main store
        </button>
      </p>
    </div>
  );
};
export default App;
