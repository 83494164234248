import { useState, useEffect } from 'react';

import CountryName from './CountryName';
import CountryButton from './CountryButton';
// import Modal from 'react-bootstrap/Modal';
import logo from '../assets/logo-2021-handwriting-dark.png';
// import CANUS from '../assets/canus.png';
import gsap from 'gsap';
import { PrinterFill, Truck } from 'react-bootstrap-icons';

const ChooseStore = ({ ipData, iframeLoaded, errorObj }) => {
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [hoverMessage, setHoverMessage] = useState('');
  const [shippingAvailable, setShippingAvailable] = useState(false);

  const fadeInChooseStoreModal = () => {
    gsap.fromTo(
      '.choose-store',
      { opacity: 1, y: '100%' },
      {
        duration: 0.5,
        delay: 0,
        opacity: 1,
        y: 0,
        ease: 'power.out',
        onComplete: function () {
          console.log('complete', this);
          console.log();
          this._targets[0].style.transform = 'translate(-50%,0)';
        },
      }
    );
  };

  useEffect(() => {
    if ((ipData && !mounted && iframeLoaded && imagesLoaded) || (errorObj && !mounted)) {
      fadeInChooseStoreModal();
      setMounted(true);
    }
  }, [ipData, mounted, iframeLoaded, errorObj, imagesLoaded]);

  return (
    <div className="choose-store" style={{ opacity: 0 }}>
      <section>
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
        </header>
        <CountryName ipData={ipData}></CountryName>
        <div className="spacer"></div>
      </section>
      <section>
        <CountryButton
          ipData={ipData}
          setHoverMessage={setHoverMessage}
          setShippingAvailable={setShippingAvailable}
          setImagesLoaded={setImagesLoaded}
        ></CountryButton>
        {hoverMessage ? (
          <div style={{ position: 'relative', width: '100%' }}>
            <div style={{ position: 'absolute', width: '100%', textAlign: 'center' }}>
              {shippingAvailable ? (
                <p>
                  <Truck /> Shipping available
                </p>
              ) : (
                <p>Pickup only</p>
              )}
              <p>
                <PrinterFill /> {hoverMessage}
              </p>
            </div>
          </div>
        ) : null}
      </section>
    </div>
  );
};

export default ChooseStore;
